import { default as _91_91pagenumber_93_93vZqnUMj6MnMeta } from "/app/pages/[slug]-offers/[id]/[[pagenumber]].vue?macro=true";
import { default as indexprURIxAkqqMeta } from "/app/pages/[slug]-offers/index.vue?macro=true";
import { default as indexq38bJr6bNoMeta } from "/app/pages/[slug]/index.vue?macro=true";
import { default as shops26BPm5WnvgMeta } from "/app/pages/[slug]/shops.vue?macro=true";
import { default as indexbUTYpCP6oZMeta } from "/app/pages/app/index.vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91slug_93z5slPNPEhUMeta } from "/app/pages/brands/[slug].vue?macro=true";
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as index51lKbWy4U0Meta } from "/app/pages/categories/[slug]/index.vue?macro=true";
import { default as indexOcsRkjobiDMeta } from "/app/pages/categories/index.vue?macro=true";
import { default as _91slug_93lM4cBooRheMeta } from "/app/pages/cities/[slug].vue?macro=true";
import { default as indexWKuDFIZg1bMeta } from "/app/pages/cities/index.vue?macro=true";
import { default as _91_91pagenumber_93_93Wt4AEG5HzEMeta } from "/app/pages/embed/[id]/[[pagenumber]].vue?macro=true";
import { default as feature_45flagsofWlQsANOfMeta } from "/app/pages/feature-flags.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexiPWDApGVsoMeta } from "/app/pages/new/index.vue?macro=true";
import { default as _91slug_93umPHiMEucAMeta } from "/app/pages/offers/[slug].vue?macro=true";
import { default as indexrE58Se8eV6Meta } from "/app/pages/offers/index.vue?macro=true";
import { default as _91referenceId_93a08j9mRIpMMeta } from "/app/pages/search/[searchQuery]/[type]/[referenceId].vue?macro=true";
import { default as index1VVBhUQenwMeta } from "/app/pages/search/[searchQuery]/index.vue?macro=true";
import { default as indexqybDxIRgfkMeta } from "/app/pages/shops/index.vue?macro=true";
export default [
  {
    name: "slug-offers-id-pagenumber___nl-NL",
    path: "/:slug()-aanbiedingen/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue")
  },
  {
    name: "slug-offers-id-pagenumber___nl",
    path: "/nl/:slug()-folder/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue")
  },
  {
    name: "slug-offers-id-pagenumber___fr",
    path: "/fr/:slug()-folder/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93vZqnUMj6MnMeta || {},
    component: () => import("/app/pages/[slug]-offers/[id]/[[pagenumber]].vue")
  },
  {
    name: "slug-offers___nl-NL",
    path: "/:slug()-aanbiedingen",
    component: () => import("/app/pages/[slug]-offers/index.vue")
  },
  {
    name: "slug-offers___nl",
    path: "/nl/:slug()-folder",
    component: () => import("/app/pages/[slug]-offers/index.vue")
  },
  {
    name: "slug-offers___fr",
    path: "/fr/:slug()-folder",
    component: () => import("/app/pages/[slug]-offers/index.vue")
  },
  {
    name: "slug___nl-NL",
    path: "/:slug()",
    component: () => import("/app/pages/[slug]/index.vue")
  },
  {
    name: "slug___nl",
    path: "/nl/:slug()",
    component: () => import("/app/pages/[slug]/index.vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug()",
    component: () => import("/app/pages/[slug]/index.vue")
  },
  {
    name: "slug-shops___nl-NL",
    path: "/:slug()/folder-aanbiedingen",
    component: () => import("/app/pages/[slug]/shops.vue")
  },
  {
    name: "slug-shops___nl",
    path: "/nl/:slug()/folder-aanbiedingen",
    component: () => import("/app/pages/[slug]/shops.vue")
  },
  {
    name: "slug-shops___fr",
    path: "/fr/:slug()/foldre-offres",
    component: () => import("/app/pages/[slug]/shops.vue")
  },
  {
    name: "app___nl-NL",
    path: "/app",
    component: () => import("/app/pages/app/index.vue")
  },
  {
    name: "app___nl",
    path: "/nl/app",
    component: () => import("/app/pages/app/index.vue")
  },
  {
    name: "app___fr",
    path: "/fr/app",
    component: () => import("/app/pages/app/index.vue")
  },
  {
    name: "blog-slug___nl-NL",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog___nl-NL",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "brands-slug___nl-NL",
    path: "/merken/:slug()",
    component: () => import("/app/pages/brands/[slug].vue")
  },
  {
    name: "brands-slug___nl",
    path: "/nl/merken/:slug()",
    component: () => import("/app/pages/brands/[slug].vue")
  },
  {
    name: "brands-slug___fr",
    path: "/fr/marques/:slug()",
    component: () => import("/app/pages/brands/[slug].vue")
  },
  {
    name: "brands___nl-NL",
    path: "/merken",
    component: () => import("/app/pages/brands/index.vue")
  },
  {
    name: "brands___nl",
    path: "/nl/merken",
    component: () => import("/app/pages/brands/index.vue")
  },
  {
    name: "brands___fr",
    path: "/fr/marques",
    component: () => import("/app/pages/brands/index.vue")
  },
  {
    name: "categories-slug___nl-NL",
    path: "/categorieen/:slug()",
    component: () => import("/app/pages/categories/[slug]/index.vue")
  },
  {
    name: "categories-slug___nl",
    path: "/nl/categorieen/:slug()",
    component: () => import("/app/pages/categories/[slug]/index.vue")
  },
  {
    name: "categories-slug___fr",
    path: "/fr/categories/:slug()",
    component: () => import("/app/pages/categories/[slug]/index.vue")
  },
  {
    name: "categories___nl-NL",
    path: "/categorieen",
    component: () => import("/app/pages/categories/index.vue")
  },
  {
    name: "categories___nl",
    path: "/nl/categorieen",
    component: () => import("/app/pages/categories/index.vue")
  },
  {
    name: "categories___fr",
    path: "/fr/categories",
    component: () => import("/app/pages/categories/index.vue")
  },
  {
    name: "cities-slug___nl-NL",
    path: "/steden/:slug()",
    component: () => import("/app/pages/cities/[slug].vue")
  },
  {
    name: "cities-slug___nl",
    path: "/nl/steden/:slug()",
    component: () => import("/app/pages/cities/[slug].vue")
  },
  {
    name: "cities-slug___fr",
    path: "/fr/villes/:slug()",
    component: () => import("/app/pages/cities/[slug].vue")
  },
  {
    name: "cities___nl-NL",
    path: "/steden",
    component: () => import("/app/pages/cities/index.vue")
  },
  {
    name: "cities___nl",
    path: "/nl/steden",
    component: () => import("/app/pages/cities/index.vue")
  },
  {
    name: "cities___fr",
    path: "/fr/villes",
    component: () => import("/app/pages/cities/index.vue")
  },
  {
    name: "embed-id-pagenumber___nl-NL",
    path: "/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue")
  },
  {
    name: "embed-id-pagenumber___nl",
    path: "/nl/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue")
  },
  {
    name: "embed-id-pagenumber___fr",
    path: "/fr/embed/:id()/:pagenumber?",
    meta: _91_91pagenumber_93_93Wt4AEG5HzEMeta || {},
    component: () => import("/app/pages/embed/[id]/[[pagenumber]].vue")
  },
  {
    name: "feature-flags___nl-NL",
    path: "/feature-flags",
    component: () => import("/app/pages/feature-flags.vue")
  },
  {
    name: "feature-flags___nl",
    path: "/nl/feature-flags",
    component: () => import("/app/pages/feature-flags.vue")
  },
  {
    name: "feature-flags___fr",
    path: "/fr/feature-flags",
    component: () => import("/app/pages/feature-flags.vue")
  },
  {
    name: "index___nl-NL",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "new___nl-NL",
    path: "/nieuw",
    component: () => import("/app/pages/new/index.vue")
  },
  {
    name: "new___nl",
    path: "/nl/nieuw",
    component: () => import("/app/pages/new/index.vue")
  },
  {
    name: "new___fr",
    path: "/fr/nouveaux",
    component: () => import("/app/pages/new/index.vue")
  },
  {
    name: "offers-slug___nl-NL",
    path: "/aanbiedingen/:slug()",
    component: () => import("/app/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___nl",
    path: "/nl/aanbiedingen/:slug()",
    component: () => import("/app/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___fr",
    path: "/fr/offres/:slug()",
    component: () => import("/app/pages/offers/[slug].vue")
  },
  {
    name: "offers___nl-NL",
    path: "/aanbiedingen",
    component: () => import("/app/pages/offers/index.vue")
  },
  {
    name: "offers___nl",
    path: "/nl/aanbiedingen",
    component: () => import("/app/pages/offers/index.vue")
  },
  {
    name: "offers___fr",
    path: "/fr/offres",
    component: () => import("/app/pages/offers/index.vue")
  },
  {
    name: "search-searchQuery-type-referenceId___nl-NL",
    path: "/zoekresultaten/:searchQuery()/:type()/:referenceId()",
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue")
  },
  {
    name: "search-searchQuery-type-referenceId___nl",
    path: "/nl/zoekresultaten/:searchQuery()/:type()/:referenceId()",
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue")
  },
  {
    name: "search-searchQuery-type-referenceId___fr",
    path: "/fr/resultatdecherche/:searchQuery()/:type()/:referenceId()",
    component: () => import("/app/pages/search/[searchQuery]/[type]/[referenceId].vue")
  },
  {
    name: "search-searchQuery___nl-NL",
    path: "/zoekresultaten/:searchQuery()",
    component: () => import("/app/pages/search/[searchQuery]/index.vue")
  },
  {
    name: "search-searchQuery___nl",
    path: "/nl/zoekresultaten/:searchQuery()",
    component: () => import("/app/pages/search/[searchQuery]/index.vue")
  },
  {
    name: "search-searchQuery___fr",
    path: "/fr/resultatdecherche/:searchQuery()",
    component: () => import("/app/pages/search/[searchQuery]/index.vue")
  },
  {
    name: "shops___nl-NL",
    path: "/winkels",
    component: () => import("/app/pages/shops/index.vue")
  },
  {
    name: "shops___nl",
    path: "/nl/winkels",
    component: () => import("/app/pages/shops/index.vue")
  },
  {
    name: "shops___fr",
    path: "/fr/magasins",
    component: () => import("/app/pages/shops/index.vue")
  }
]