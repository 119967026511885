import type { MaybeComputedElementRef, UnRefElementReturn } from "@vueuse/core";

/**
 * For a grid container, this composable will make sure that only full rows are displayed.
 * Rows that are not full will be hidden with zero height.
 * @param target The grid container element
 * @param childrenSelector Optional selector for the children in the grid container, usefull when only certain children should be considered
 * @returns void
 */
export default function useFullRowsOnly(
  target: MaybeComputedElementRef | Ref<null> | HTMLElement,
  childrenSelector?: string
) {
  // Client only
  if (import.meta.server) return;

  // Check if the item has display grid
  const { width: targetWidth } = useElementSize(target);

  const el = ref<UnRefElementReturn>(null);

  const targetChildren = ref<Element[]>([]);

  const getTargetChildren = () => {
    el.value = unrefElement(target);
    return Array.from(
      el.value?.querySelectorAll(childrenSelector ?? "*") ?? []
    );
  };

  // Use a mutation observer to watch if children are added or removed
  useMutationObserver(
    target,
    () => {
      targetChildren.value = getTargetChildren();
    },
    {
      childList: true,
    }
  );

  const childCount = computed(() => targetChildren.value?.length ?? 0);
  const childWidth = ref(0);

  // Watch the targetWidth, if that changed it means the target templateRef is not null any more either
  watch(
    targetWidth,
    () => {
      targetChildren.value = getTargetChildren();
      childWidth.value = el.value?.children[0].clientWidth ?? 0;
    },
    { immediate: true }
  );

  // Calculate the number of fully filled rows
  const totalFullRows = computed(() => {
    if (!targetWidth.value || !childCount.value || !childWidth.value) return;

    const itemsPerRow = Math.floor(targetWidth.value / childWidth.value);

    return Math.floor(childCount.value / itemsPerRow);
  });

  watch(totalFullRows, (fullRows) => {
    // Show at least one row
    if (fullRows === 0) fullRows = 1;

    if (fullRows) {
      el.value?.style.setProperty(
        "grid-template-rows",
        `repeat(${fullRows}, 1fr)`
      );
      el.value?.style.setProperty("grid-auto-rows", "0");
      el.value?.style.setProperty("overflow-y", "hidden");
    }
  });
}
