<template>
  <div>
    <div ref="search" class="search-field">
      <BaseInput
        ref="searchInput"
        v-model="searchQuery"
        class="search-input"
        :class="{
          'autocomplete-open':
            (data?.searchSuggestions.length || fetching) && isAutocompleteOpen,
        }"
        type="search"
        icon="search"
        :placeholder="$t('navbar.search.placeholder')"
        @keyup.enter.stop="enterPressed"
        @keydown.down.stop="downPressed"
        @keydown.up.stop="upPressed"
      />
      <SearchAutocomplete
        ref="searchAutocomplete"
        :items="data?.searchSuggestions ?? []"
        :query="searchQuery"
        :fetching="fetching"
        :selected-item="selectedItem"
        class="search-autocomplete"
        :class="{
          'autocomplete-hidden': !isAutocompleteOpen,
        }"
        @click="loseFocus"
      />
    </div>
    <div v-if="isAutocompleteOpen" class="body-overlay" />
  </div>
</template>

<script setup lang="ts">
import { useSearchSuggestionsQuery } from "~/graphql/generated";

const searchAutocomplete = ref<HTMLElement | null>(null);

const searchQuery = ref("");

// Set focusable by parent
const searchInput = ref<HTMLElement | null>(null);
defineExpose({ focus: () => searchInput.value?.focus() });

// Open the suggestions if the element or descendants have focus
const search = ref<HTMLElement | null>(null);
const { focused } = useFocusWithin(search);
const isAutocompleteOpen = computed(
  () => focused.value && searchQuery.value.length > 0
);

// lose the focus
const loseFocus = () => {
  (document.activeElement as HTMLElement | undefined)?.blur();
};

// Bugfix: https://github.com/vueuse/vueuse/issues/3749
watch(focused, () => {});

// Get the users location (if set)
const location = useLocationState();

// Get the search suggestions
const { data, fetching } = await useSearchSuggestionsQuery({
  variables: computed(() => {
    return {
      searchSuggestions: {
        query: searchQuery.value,
        ...(location.value && {
          point: {
            latitude: location.value.lat,
            longitude: location.value.long,
          },
        }),
      },
    };
  }),
});

/*
 * Keyboard navigation
 */
const selectedItem = ref(-1);
const enterPressed = () => {
  if (searchQuery.value.length > 0) {
    const router = useRouter();
    const localePath = useLocalePath();

    // If we have selected an item, use the reference
    const autoCompleteItem = data.value?.searchSuggestions[selectedItem.value];

    if (autoCompleteItem) {
      router.push(
        localePath({
          name: "search-searchQuery-type-referenceId",
          params: {
            searchQuery: autoCompleteItem.name,
            referenceId: autoCompleteItem.referenceId,
            type: enumToFriendly(autoCompleteItem.type),
          },
          query: {
            q: searchQuery.value,
          },
        })
      );
    } else {
      router.push(
        localePath({
          name: "search-searchQuery",
          params: {
            searchQuery: searchQuery.value,
          },
        })
      );
    }

    loseFocus();
  }
};

const downPressed = () => {
  if (!data.value?.searchSuggestions) return;
  if (selectedItem.value === data.value?.searchSuggestions.length - 1) return;

  selectedItem.value++;
};

const upPressed = () => {
  if (selectedItem.value === -1) return;

  selectedItem.value--;
};

// Reset the selected item when typing
watch(searchQuery, () => {
  selectedItem.value = -1;
});
</script>

<style lang="scss" scoped>
.search-field {
  z-index: 10;
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.autocomplete-open.search-input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search-autocomplete {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
}

.search-input {
  width: 100%;
}

.body-overlay {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($dark-100, 0.5);
}

.autocomplete-hidden {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
</style>
