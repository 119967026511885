<template>
  <BaseSelect v-model="lang" class="language-selector">
    <option value="nl" :selected="locale === 'nl'">
      {{ $t("languages.be") }}
    </option>
    <option value="fr" :selected="locale === 'fr'">
      {{ $t("languages.fr") }}
    </option>
  </BaseSelect>
</template>

<script setup lang="ts">
const { locale, setLocale } = useI18n();
const lang = ref(locale.value);

// Replace the route when locale changes
watch(lang, async () => {
  // Clear urql cache
  const { $urqlResetCache } = useNuxtApp();
  $urqlResetCache();

  // We clear this to make sure the infinite loading component does not end up in a funky state.
  clearNuxtData();
  clearNuxtState();

  await setLocale(lang.value);

  const router = useRouter();
  const localePath = useLocalePath();
  router.push(localePath("/"));
});
</script>

<style lang="scss" scoped>
.language-selector {
  height: 68px;
}
</style>
